.container {
    padding: var(--spacing-4) 0;
}

.tiptap-editor {
    border: var(--border-width-1) solid var(--color-gray-400);
    border-radius: var(--border-radius-2);
    flex-direction: column;
    padding: var(--spacing-4);
    min-height: 200px;
    background: white;

    p {
        margin-top: 0;
        margin-bottom: 0;
    }
}
